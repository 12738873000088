
.profile-picture-form {
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}
.profile-picture-form .current-picture {
    max-height: 200px;
    max-width: 200px;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 10px;
}


